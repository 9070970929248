<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/logo/logo_sendpick.png" alt="login" ref='logoPreview' class="mx-auto logo">
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8 login-tabs-container">

                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Login</h4>
                  <p>Welcome back, please login to your account.</p>
                </div>

                <div style="padding-bottom:30px;">
                  <vs-input
                      name="email"
                      icon-no-border
                      icon="icon icon-user"
                      icon-pack="feather"
                      label-placeholder="Email"
                      v-model="email"
                      class="w-full"/>

                  <vs-input
                      type="password"
                      name="password"
                      icon-no-border
                      icon="icon icon-lock"
                      icon-pack="feather"
                      label-placeholder="Password"
                      v-model="password"
                      class="w-full mt-6" />

                  <div class='mb-12 mt-3'>
                      <a  @click='openForgotPassword()' class='pull-right'>Forgot your password ?</a>
                  </div>
                  <vs-divider></vs-divider>
                  <vs-button @click="login" class="float-right">Login</vs-button>
                </div>

              </div>
            </div>

          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>

import {mapActions} from 'vuex'
export default{
  data() {
    return {
      email: "",
      password: "",
    }
  },
  created() {
     this.getSettingApplication()
  },
  methods:{
    ...mapActions({
        dispatchShow : 'application/show'
    }),
    openForgotPassword() {
        this.$router.push({name:'forgot-password'})
    },
    async getSettingApplication() {
        let { data } = await this.dispatchShow('general/logo')
        // console.log(data)
        if(data.data) {
            if(data.data.logo) {
                this.$refs.logoPreview.setAttribute('src', data.data.logo)
            }
        }

    },
    async login(){
      this.$vs.loading()
      let payload = {username:this.email, password: this.password}
      try {
        await this.$store.dispatch('auth/login', payload)
        this.$vs.loading.close()
        this.$router.push({name: 'home'}) 
      } catch (error) {
        this.$vs.loading.close()
        this.$vs.notify({
          title: 'Oops!',
          text: error.data.message,
          color: 'danger'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#page-login {
  .social-login-buttons {
    .bg-facebook { background-color: #1551b1 }
    .bg-twitter { background-color: #00aaff }
    .bg-google { background-color: #4285F4 }
    .bg-github { background-color: #333 }
  }
}
.logo {
  max-width: 300px;
}
</style>
